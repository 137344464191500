import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LazyDialogService } from '@ih/lazy-dialog';
import { defer, firstValueFrom, from, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { type SaveChangesDialogComponent, type SaveChangesDialogOptions } from './save-changes-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SaveChangesDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  cancelled$ = new Subject<void>();
  pageTitle!: string;
  isModified = false;

  public setModified(modified?: boolean): void {
    if (typeof modified === 'undefined') modified = true;

    this.isModified = modified;
  }

  public reset(): void {
    this.pageTitle = '';
    this.isModified = false;
  }

  public async open(title: string): Promise<any> {
    const saveChangesDialogComponent: ComponentType<SaveChangesDialogComponent> = await import(
      './save-changes-dialog.component'
    ).then((m) => m.SaveChangesDialogComponent);

    const dialog = await this.lazyDialog.getDialogService();

    return firstValueFrom(
      dialog
        .open(saveChangesDialogComponent, {
          data: {
            title
          } as SaveChangesDialogOptions,
          panelClass: ['save-changes-dialog', 'basic-dialog']
        })
        .afterClosed()
        .pipe(
          tap((result) => {
            if (!result) {
              this.cancelled$.next();
            }
          })
        )
    );
  }

  canActivate(): boolean {
    this.reset();

    return true;
  }

  // Implements the CanDeactivate interface to conditionally prevent leaving the page
  canDeactivate(): Observable<boolean> {
    // Returns an observable resolving into a suitable guarding value
    return defer(() => (this.isModified ? from(this.open(this.pageTitle)) : of(true)));
  }
}
